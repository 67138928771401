import React, { lazy, Suspense } from "react";
import { useLocalStorage } from "usehooks-ts";
import { IS_SKIP_INTO } from "../components/common/consts";
import ShowContainer from "../components/mui/ShowContainer";
import HomeMain from "../components/home/HomeMain";
import "../style/home/home.scss";

const HomeHooks = lazy(() => import("../components/home/HomeHooks"));

export default function Home() {
  const [confirmAge] = useLocalStorage("confirmAge", IS_SKIP_INTO || "loading");

  return (
    <>
      <Suspense>
        <HomeHooks />
      </Suspense>
      <ShowContainer condition={confirmAge && confirmAge !== "loading"}>
        <HomeMain />
      </ShowContainer>
    </>
  );
}

import KronaChatBase from "../controls/janus/KronaChatBase";
import { useDispatch, useSelector } from "react-redux";
import {
  ENUM_CHAT_MODE,
  ENUM_STATUS_WINDOW,
  setChatActiveMode,
  setLoadingBroadcaster,
  setMessages,
  setNewMessages,
  setStatusWindow,
  setTips,
  setTipsMenu,
} from "../store/chatSlice";
import { sleep } from "../utils/utils";
import { TEST_MODE } from "../components/common/consts";
import { TYPE_ENUM } from "../components/chat/chat/ChatFree";
import { useNavigate } from "react-router-dom";
import JanusStatuses from "../controls/janus/site/JanusStatuses";
import JanusInside from "../controls/janus/site/JanusInside";
import { useEffect, useMemo } from "react";
import { API_CHAT } from "../api/chat";
import { errorToast, infoToast, successToast } from "../components/mui/Toaster";
import { useLocalStorage } from "usehooks-ts";
import JanusState from "../controls/janus/site/JanusState";
import JanusDisconnect from "../controls/janus/site/JanusDisconnect";

// глобальные переменные описаны через window

// useJanus - в хуке одна инициализация которая может менять глобальные переменные, зависящая от useEffect с изменением broadcasterId.
// при вызове хука с пустым объектом в зависимостях контекст не меняется

export default function useJanus({ broadcasterId, chatId, videoRef, audioRef }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastAmount, setLastAmount] = useLocalStorage("lastAmount", {});

  const userInfo = useSelector((state) => state.user.user);
  const userId = useMemo(() => userInfo.id, [userInfo]);

  JanusState.initVideoAudio({ audioRef, videoRef });

  // @external@

  // changeChatMode - внешняя функция смены чата
  const changeChatMode = async (mode) => {
    if (![ENUM_CHAT_MODE.private, ENUM_CHAT_MODE.exclusive].includes(mode)) {
      console.log("setLoadingBroadcaster", true);
      dispatch(setLoadingBroadcaster(true));
    }

    for (const feed of Object.values(JanusState.clientChat._remotefeeds)) {
      await feed.startStopByMode(mode);
    }

    return JanusState.statuses.change(mode);
  };

  // destroyCurrent - функция дестроя
  async function destroyCurrent(reconnectEnabled) {
    JanusState.isAwait = true;
    if (reconnectEnabled) JanusDisconnect.reconnectEnabled = false;
    const res = await JanusState.clientChat?.destroy?.();
    JanusState.isAwait = false;
    // CRUTCH
    setTimeout(() => {
      if (reconnectEnabled) JanusDisconnect.reconnectEnabled = false;
    }, 200);
    return res;
  }

  // onSendMessage - оправка сообщений
  const onSendMessage = (text, onSendMessage) => {
    const clientChat = JanusState.clientChat;
    const isPrivate = [ENUM_CHAT_MODE.exclusive, ENUM_CHAT_MODE.private].includes(
      window.chatActiveMode
    );
    const privateMes = onSendMessage || isPrivate;
    const mesId = JanusState.clientChat?.broadcasterFeed?.id;
    console.log("mesId", mesId);
    clientChat.localfeed.send(text, privateMes ? [mesId] : []);
  };

  // offerStream - подключение видео пользователю к модели
  const offerStream = async (devices, enablingVideo, enableAudio) => {
    console.log("offerStream");
    console.log(devices, enablingVideo, enableAudio);
    const clientChat = JanusState.clientChat;
    const isOnline = clientChat?.broadcasterFeed;

    if (!isOnline) return;

    if (clientChat?.settings?.ownVideoEnabled || clientChat.settings?.ownAudioEnabled) {
      console.log("unpublish");
      await clientChat.localfeed.unpublish();
    }

    if (enablingVideo || enableAudio) {
      console.log("devices", devices);
      await clientChat.localfeed.publishWitchParams({
        ownVideoEnabled: enablingVideo,
        ownAudioEnabled: enableAudio,
        audioDevice: devices?.microId,
        videoDevice: devices?.videoId,
        videoResolution: {
          width: devices.resolution?.split("x")[0],
          height: devices.resolution?.split("x")[1],
        },
      });

      console.log(devices.resolution?.split("x")[0]);
      console.log(devices.resolution?.split("x")[1]);

      clientChat.settings.ownVideoEnabled = enablingVideo;
      clientChat.settings.ownAudioEnabled = enableAudio;
    }
  };

  // getModelMode - получение текущего режима модели
  const getModelMode = () => JanusState.modelMode;

  // sendTips - отправка чаевых модели
  const postTips = async (info) => {
    const chatId = JanusState.clientChat.chatId;

    try {
      const e = await API_CHAT.postTips(chatId, info);
      console.log(e);

      const message_1 = {
        value: info.value,
        nick: info.nickname,
        type: TYPE_ENUM.tipped,
      };

      dispatch(setNewMessages(message_1));
      successToast(e.data.message);
    } catch (message_3) {
      return errorToast(message_3);
    }
  };

  // sendTips - отправка чаевых модели ищ lovense
  const postLovenseTips = async (info) => {
    const chatId = JanusState.clientChat.chatId;

    try {
      const res = await API_CHAT.postTips(chatId, info);

      successToast(res.data.message);
    } catch (err) {
      return errorToast(err);
    }
  };

  // rejectPrivate - отмена приглашения приват
  const cancelPrivate = async () => {
    const chatId = JanusState.clientChat?.chatId;
    const requestId = JanusState.requestId;
    return API_CHAT.cancelPrivate(chatId, requestId)
      .then((e) => {
        if (e.data.message) infoToast(e.data.message);
      })
      .catch((e) => {
        if (typeof e === "string") errorToast(e);
        if (e.data.message) errorToast(e.data.message);
      });
  };

  //stopPrivate - остановиться приват
  const stopPrivate = async () => {
    const chatId = JanusState.clientChat?.chatId;
    await API_CHAT.stopChat(chatId)
      .then((e) => {
        console.log(e);
        // const chat_mode = e.data.chat.mode;
        // const mode = modeByStatus[chat_mode];
        // this.dispatch(setStatusWindow(mode));
        // dispatch(setChatActiveMode(chat_mode));
      })
      .catch((e) => {
        console.log(e);
        if (typeof e === "string") errorToast(e);
      });

    JanusState.isRestore = false;
  };

  // getSetting - получить настройки
  const getSetting = () => JanusState.statuses?.getSetting?.();

  const initJanus = async (broadcasterId, chatId) => {
    if (!broadcasterId) return false;

    while (JanusState.isAwait) {
      console.log("awaitRef", JanusState.isAwait);
      await sleep(300);
    }
    // if (JanusState.clientChat?.chatId) return;

    const statuses = new JanusStatuses({ dispatch, broadcasterId });
    JanusState.statuses = statuses;

    if (TEST_MODE) {
      dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
      // dispatch(setMessages([]));
      dispatch(setChatActiveMode(ENUM_CHAT_MODE.free));
      return false;
    }

    const user = {
      id: userId || 0,
      nickname: userInfo.nickname || "Anonymous",
      email: userInfo.email || "e@mail.ru",
      type: userInfo.type || "client",
    };

    console.log("init KronaChatBase", broadcasterId, userId);
    JanusState.isSuccessInit = false;
    const clientChat = new KronaChatBase({
      chattype: "client",
      clientChatMode: ENUM_CHAT_MODE.pause,
      user: { id: userId, nickname: user.nickname, email: user.email, type: user.type },
    });

    console.log("init clientChat", clientChat);
    const janusSite = new JanusInside({
      dispatch,
      navigate,
      destroyCurrent,
      initJanus,
      broadcasterId,
      chatId: chatId,
    });

    JanusState.initChatSite({ clientChat, janusSite });
    janusSite.listener(clientChat, statuses);
    console.log("chatId", chatId);
    const success = await clientChat.initialize(broadcasterId, chatId);
    console.log("success", success);
    janusSite.chatId = clientChat._chatId;

    API_CHAT.getTips(clientChat._chatId)
      .then((e) => {
        const tips = e.data.tips?.global || [];
        const tipsMenu = e.data.tips?.user || [];
        dispatch(setTips(tips));
        dispatch(setTipsMenu(tipsMenu));
        console.log("lastAmount", lastAmount);
        if (!lastAmount.amount) setLastAmount({ amount: tips[0].value, ...tips[0] });
      })
      .catch(errorToast);

    if (!clientChat._chatId) {
      console.log("status offline");
      dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
      console.log("no broadcasterFeed", clientChat.broadcasterFeed);

      return false;
    }

    if (!success) {
      console.log("status offline");
      dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
      dispatch(setChatActiveMode(ENUM_CHAT_MODE.pause));

      return false;
    }

    JanusState.isSuccessInit = true;
    statuses.getSetting();
    const mes = await clientChat.history();
    const messages = mes.messages;
    console.log("mes");
    if (mes?.data?.message) infoToast(mes.data.message);
    if (messages && messages?.length) dispatch(setMessages(messages));
    return true;
  };

  // useEffect - основная логика
  useEffect(() => {
    if (!broadcasterId) return;

    console.log("initJanus", chatId);
    JanusState.inInitJanus = true;
    initJanus(broadcasterId, chatId).finally(() => {
      console.log("initJanus finally");
      JanusState.inInitJanus = false;
    });

    //CRUTCH [broadcasterId, chatId]
    return () => {
      console.log("initJanus return");
      JanusState.inInitJanus - false;
    };
  }, [broadcasterId]);

  return {
    changeChatMode,
    destroyCurrent,
    onSendMessage,
    offerStream,
    getModelMode,
    cancelPrivate,
    postTips,
    postLovenseTips,
    stopPrivate,
    getSetting,
  };
}

import React, { lazy, Suspense, useEffect, useRef } from "react";
import ChoseLang from "../../mui/ChoseLang";
import CusFind from "../../mui/CusFind";
import { useDispatch, useSelector } from "react-redux";
import { setNickName, setTypeModel } from "../../../store/commonSlice";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../mui/Logo";
import ShowContainer from "../../mui/ShowContainer";
import HeaderSegment from "./HeaderSegment";
import { useNavigate } from "react-router-dom";
import { GM_HEADER } from "../../../store/enums.js";

const HeaderButton = lazy(() => import("../HeaderButton"));

export default function Header({ setOpenLoginModal }) {
  const nickname = useSelector((state) => state.common.nickname);
  const user = useSelector((state) => state.user.user?.id);
  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);
  const mobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);
  const width = useSelector((state) => state.deviceSize.width);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cbTimeRef = useRef();

  const goToHome = () => {
    if (nickname) navigate("/");
  };

  useEffect(() => {
    const value = optionsSex.find((el) => el.selected)?.value;
    if (value) dispatch(setTypeModel(value));
  }, [optionsSex]);

  const findPlaceholder = useMemo(
    () => (user && width < GM_HEADER ? "By nickname" : "Sort a model by nickname"),
    [user, width]
  );

  return (
    <>
      <header className={`header ${user ? "authorized" : ""}`}>
        <div className={"header__content"}>
          <Logo className="header__logo" />
          <HeaderSegment isTopSegment />

          <ShowContainer condition={!mobileHeader}>
            <div className="header__find">
              <CusFind
                value={nickname}
                // TODO добавить оптимизацию
                onChange={(e) => {
                  dispatch(setNickName(e.target.value));
                  clearTimeout(cbTimeRef.current);
                  cbTimeRef.current = setTimeout(goToHome, 500);
                }}
                placeholder={t(findPlaceholder)}
              />
            </div>
          </ShowContainer>

          <div className="header__lang">
            <ChoseLang />
          </div>
          <Suspense>
            <HeaderButton setOpenLoginModal={setOpenLoginModal} />
          </Suspense>
        </div>

        <ShowContainer condition={mobileHeader}>
          <HeaderSegment classNameSegment="mobile-segment" />
        </ShowContainer>
      </header>
    </>
  );
}

import React, { lazy, Suspense, useEffect } from "react";
import IconFilters from "../../images/jsIcons/IconFilters";
import IconArrowDown from "../../images/jsIcons/IconArrowDown";
import SortBySelect from "./SortBySelect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoaderContainer from "../mui/LoaderContainer";

const MobileUnderHeader = lazy(() => import("./header/MobileUnderHeader"));
const CategoriesCarousel = lazy(() => import("./CategoriesCarousel"));

export default function HomeUnderHeader({ collapsed, setCollapsed }) {
  const { t } = useTranslation();

  const isMobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);
  const categories = useSelector((state) => state.siteInfo.commonSiteInfo.options?.shown);

  const isCollapsedClass = collapsed ? "collapsed" : "";

  useEffect(() => {
    if (!isMobileHeader) return;
    setCollapsed(true);
  }, [isMobileHeader]);

  return (
    <div className="home__unheader">
      <div className="home__unheader__content">
        {isMobileHeader ? (
          <Suspense fallback={<LoaderContainer center loader />}>
            <MobileUnderHeader />
          </Suspense>
        ) : (
          <>
            <button
              className={`home__unheader__filters ${isCollapsedClass}`}
              onClick={() => setCollapsed((prev) => !prev)}
            >
              <div>
                <IconFilters />
                <span>{t("Sorting Priority")}</span>
              </div>
              <IconArrowDown className={!isCollapsedClass ? "rotate180" : ""} />
            </button>
            <Suspense>
              <CategoriesCarousel className={"home__unheader"} categories={categories} />
            </Suspense>
            <SortBySelect />
          </>
        )}
      </div>
    </div>
  );
}

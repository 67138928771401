import React, { useMemo } from "react";
import ShowContainer from "../../mui/ShowContainer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function SendPlug({
  skipPlug,
  sendPlug,
  currentToken,
  setCurrentToken,
  onChangeAmount,
}) {
  const { t } = useTranslation();
  const tips = useSelector((state) => state.chat.tips);

  const curTokenNum = useMemo(() => currentToken.amount, [currentToken]);

  const onlyNumber = (num) => {
    const sanitizedNum = num.replace(/[^0-9]/g, "");
    return sanitizedNum.replace(/^0+/, ""); // Remove leading zeros
  };

  return (
    <ShowContainer condition={sendPlug}>
      <div className="chat__send__plug">
        <ShowContainer condition={tips.length > 1 && !skipPlug}>
          <div className="chat__send__plug__tips">
            {tips.map((el) => (
              <button
                key={el.id}
                onClick={() => setCurrentToken({ ...el, amount: el.value })}
                className={currentToken.amount === el.value ? "active" : ""}
              >
                {el.value}
              </button>
            ))}
          </div>
        </ShowContainer>
        <div className="chat__send__plug__current">
          <label className="chat__send__plug__current__label">{t("Amount of tokens")}</label>
          <input
            step={1}
            min={0}
            className="chat__send__plug__current__value"
            value={curTokenNum}
            onChange={(e) => {
              const amount = +onlyNumber(e.target.value);
              setCurrentToken({ amount });
              onChangeAmount(e);
            }}
          />
        </div>
      </div>
    </ShowContainer>
  );
}

import imgPlug from "./images/template/image1289.png";
import imgPlug1 from "./images/template/image1.jpg";
import { TYPE_ENUM } from "./components/chat/chat/ChatFree";

export const testSettingChat = {
  "exclusive-chat": {
    "allow-broadcaster-start-exclusive-chat": false,
    "allow-broadcaster-start-free-chat": true,
    "allow-broadcaster-start-paid-chat": true,
    "allow-broadcaster-start-pause-chat": true,
    "allow-broadcaster-start-private-chat": false,
    "allow-broadcaster-start-view-chat": true,
    "allow-client-start-exclusive-chat": true,
    "allow-client-view-mode": false,
    "automatically-show-client-webcam-window": true,
    billing: {
      price: { maximal: 400, minimal: 20, value: 20 },
      time: { maximal: 10, minimal: 5, value: 5 },
    },
    "client-can-enable-webcam": true,
    "direct-tips-form-available": false,
    enabled: true,
    "non-billable-time-enabled": true,
    "describe-service": "а вот тут честно вдвоём",
    "non-billable-time-sec": 15,
  },
  "free-chat": {
    "allow-broadcaster-show-desktop": false,
    "allow-broadcaster-start-exclusive-chat": false,
    "allow-broadcaster-start-free-chat": false,
    "allow-broadcaster-start-paid-chat": true,
    "allow-broadcaster-start-pause-chat": true,
    "allow-broadcaster-start-private-chat": false,
    "allow-broadcaster-start-view-chat": true,
    "allow-client-show-desktop": false,
    "allow-client-start-exclusive-chat": true,
    "allow-client-start-free-chat": false,
    "allow-client-start-paid-chat": true,
    "allow-client-start-pause-chat": false,
    "allow-client-start-private-chat": true,
    "allow-client-start-view-chat": true,
    "automatically-show-client-webcam-window": false,
    "broadcaster-set-allow-guest": false,
    "broadcaster-set-client-start-paid-chat": false,
    "broadcaster-set-client-start-private-chat": false,
    "broadcaster-set-forbid-text-chat-for-client-with-low-balance": false,
    "client-can-enable-webcam": false,
    "close-chatrom-for-clients-with-balance-less-than": 0,
    "close-chatrom-for-clients-with-low-balance": false,
    "close-for-guest-chatroom-by-timeout": false,
    "close-for-guest-chatroom-timeout": 180,
    "close-for-guest-chatroom-timeout-shared": 1,
    "direct-tips-form-available": true,
    enabled: true,
    "forbid-for-guest-typing-by-timeout": false,
    "forbid-for-guest-typing-timeout": 180,
    "forbid-for-guest-typing-timeout-shared": 1,
    "forbid-text-chat-for-client-with-balance-less-than": 0,
    "forbid-text-chat-for-client-with-low-balance": false,
    "guest-allow": true,
    "invite-required": false,
    "reset-timeouts-everyday": false,
  },
  "paid-chat": {
    "allow-broadcaster-show-desktop": false,
    "allow-broadcaster-start-exclusive-chat": false,
    "allow-broadcaster-start-free-chat": true,
    "allow-broadcaster-start-paid-chat": true,
    "allow-broadcaster-start-pause-chat": true,
    "allow-broadcaster-start-private-chat": false,
    "allow-broadcaster-start-view-chat": true,
    "allow-client-show-desktop": false,
    "allow-client-start-exclusive-chat": true,
    "allow-client-start-free-chat": false,
    "allow-client-start-paid-chat": false,
    "allow-client-start-pause-chat": false,
    "allow-client-start-private-chat": false,
    "allow-client-start-view-chat": false,
    "allow-client-view-mode": true,
    "automatically-show-client-webcam-window": false,
    billing: {
      price: { maximal: 200, minimal: 10, value: 10 },
      time: { maximal: 10, minimal: 1, value: 1 },
    },
    "broadcaster-set-client-start-private-chat": false,
    "broadcaster-set-client-view-mode": false,
    "client-can-enable-webcam": false,
    "direct-tips-form-available": false,
    enabled: true,
    "non-billable-time-enabled": true,
    "non-billable-time-sec": 15,
  },
  "pause-chat": {
    "allow-broadcaster-start-exclusive-chat": false,
    "allow-broadcaster-start-free-chat": true,
    "allow-broadcaster-start-paid-chat": true,
    "allow-broadcaster-start-pause-chat": false,
    "allow-broadcaster-start-private-chat": false,
    "allow-broadcaster-start-view-chat": true,
    "allow-client-start-exclusive-chat": false,
    "allow-client-start-free-chat": false,
    "allow-client-start-paid-chat": false,
    "allow-client-start-pause-chat": false,
    "allow-client-start-private-chat": false,
    "allow-client-start-view-chat": false,
    enabled: true,
  },
  "private-chat": {
    "allow-broadcaster-show-desktop": false,
    "allow-broadcaster-start-exclusive-chat": false,
    "allow-broadcaster-start-free-chat": true,
    "allow-broadcaster-start-paid-chat": true,
    "allow-broadcaster-start-pause-chat": true,
    "allow-broadcaster-start-private-chat": false,
    "allow-client-show-desktop": false,
    "allow-client-start-exclusive-chat": true,
    "allow-client-start-free-chat": false,
    "allow-client-start-paid-chat": false,
    "allow-client-start-pause-chat": false,
    "allow-client-start-private-chat": false,
    "allow-client-view-mode": true,
    "automatically-show-client-webcam-window": false,
    "describe-service": "только вдвоём, ну почти",
    billing: {
      price: { maximal: 200, minimal: 20, value: 20 },
      time: { maximal: 10, minimal: 5, value: 5 },
    },
    "broadcaster-set-client-view-mode": false,
    "client-can-enable-webcam": false,
    "direct-tips-form-available": false,
    enabled: true,
    "non-billable-time-enabled": true,
    "non-billable-time-sec": 15,
  },
  "target-chat": {
    "allow-broadcaster-show-desktop": false,
    "allow-broadcaster-start-exclusive-chat": false,
    "allow-broadcaster-start-free-chat": true,
    "allow-broadcaster-start-paid-chat": true,
    "allow-broadcaster-start-pause-chat": true,
    "allow-broadcaster-start-private-chat": false,
    "allow-client-show-desktop": false,
    "allow-client-start-exclusive-chat": false,
    "allow-client-start-free-chat": false,
    "allow-client-start-paid-chat": false,
    "allow-client-start-pause-chat": false,
    "allow-client-start-private-chat": false,
    "allow-client-view-mode": false,
    "automatically-show-client-webcam-window": false,
    "broadcaster-set-free-viewing": true,
    "broadcaster-set-return-tip-on-cancel": true,
    "client-can-enable-webcam": true,
    enabled: false,
    "free-viewing": false,
    "return-tip-on-cancel": false,
  },
  "text-chat": {
    "allow-broadcaster-upload-file": false,
    "allow-broadcaster-upload-file-types": "",
    "allow-client-upload-file": false,
    "allow-client-upload-file-types": "",
    "allow-google-translate-to-broadcaster": true,
    "allow-google-translate-to-client": true,
    "allow-offline-messages": false,
    "broadcaster-accept-file-without-confirm": false,
    "client-accept-file-without-confirm": false,
    "google-api-key": "",
    "image-preview-from-remote-sources": false,
    "links-show-as-simple-text": true,
    "load-last-messages": true,
    "load-last-messages-count": 50,
    "message-expire-timeout": 12960000,
    "upload-file-expire-timeout": 2592000,
    "upload-file-size-limit": 67108864,
  },
  "view-chat": {
    "allow-broadcaster-start-exclusive-chat": false,
    "allow-broadcaster-start-free-chat": true,
    "allow-broadcaster-start-paid-chat": true,
    "allow-broadcaster-start-pause-chat": true,
    "allow-broadcaster-start-private-chat": false,
    "allow-broadcaster-start-view-chat": false,
    "allow-client-start-exclusive-chat": false,
    "allow-client-start-free-chat": false,
    "allow-client-start-paid-chat": false,
    "allow-client-start-pause-chat": false,
    "allow-client-start-private-chat": false,
    "allow-client-start-view-chat": false,
    billing: {
      price: {
        maximal: 200,
        minimal: 10,
        value: 10,
      },
      time: {
        maximal: 10,
        minimal: 1,
        value: 1,
      },
    },
    "direct-tips-form-available": true,
    enabled: true,
    "non-billable-time-enabled": true,
    "non-billable-time-sec": 15,
  },
  type: "free-chat",
};

export const textSnapshot = {
  ok: true,
  gallery: {
    info: {
      total: 99,
      filtered: 99,
    },
    files: [
      {
        id: 75,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 76,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 77,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 78,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 79,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 80,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 81,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 82,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 83,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 84,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 85,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 86,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 87,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 88,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 89,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 90,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 91,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 92,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 93,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 94,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 95,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 96,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 97,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 98,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 99,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 100,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 101,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 102,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 103,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 104,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 105,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 106,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 107,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 108,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 109,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 110,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 111,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 112,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 113,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 114,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 115,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 116,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 117,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 118,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 119,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 120,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 121,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 122,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 123,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 124,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 125,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 126,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 127,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 128,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 129,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 130,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 131,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 132,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 133,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 134,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 135,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 136,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 137,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 138,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 139,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 140,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 141,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 142,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 143,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 144,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 145,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 146,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 147,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 148,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 149,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 150,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 151,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 152,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 153,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 154,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 155,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 156,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 157,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 158,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 159,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 160,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 161,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 162,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 163,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 164,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 165,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 166,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 167,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 168,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 169,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 170,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 171,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 172,
        type: "snapshot",
        extension: "png",
        title: "",
      },
      {
        id: 173,
        type: "snapshot",
        extension: "png",
        title: "",
      },
    ],
  },
};

export const testAreaCountries = [
  {
    code: "NORTH-AMERICA",
    title: "North America",
    CA: {
      code: "CA",
      name: "Canada",
    },
    US: {
      code: "US",
      name: "United States",
    },
  },
  {
    code: "EUROPE",
    title: "Europe",
    AL: {
      code: "AL",
      name: "Albania",
    },
    AD: {
      code: "AD",
      name: "Andorra",
    },
    AT: {
      code: "AT",
      name: "Austria",
    },
    BE: {
      code: "BE",
      name: "Belgium",
    },
    BA: {
      code: "BA",
      name: "Bosnia & Herzegovina",
    },
    BG: {
      code: "BG",
      name: "Bulgaria",
    },
    HR: {
      code: "HR",
      name: "Croatia",
    },
    CY: {
      code: "CY",
      name: "Cyprus",
    },
    CZ: {
      code: "CZ",
      name: "Czechia",
    },
    DK: {
      code: "DK",
      name: "Denmark",
    },
    EE: {
      code: "EE",
      name: "Estonia",
    },
    FR: {
      code: "FR",
      name: "France",
    },
    DE: {
      code: "DE",
      name: "Germany",
    },
    GI: {
      code: "GI",
      name: "Gibraltar",
    },
    GR: {
      code: "GR",
      name: "Greece",
    },
    HU: {
      code: "HU",
      name: "Hungary",
    },
    IS: {
      code: "IS",
      name: "Iceland",
    },
    IE: {
      code: "IE",
      name: "Ireland",
    },
    IT: {
      code: "IT",
      name: "Italy",
    },
    LV: {
      code: "LV",
      name: "Latvia",
    },
    LI: {
      code: "LI",
      name: "Liechtenstein",
    },
    LT: {
      code: "LT",
      name: "Lithuania",
    },
    LU: {
      code: "LU",
      name: "Luxembourg",
    },
    MT: {
      code: "MT",
      name: "Malta",
    },
    MC: {
      code: "MC",
      name: "Monaco",
    },
    ME: {
      code: "ME",
      name: "Montenegro",
    },
    MK: {
      code: "MK",
      name: "North Macedonia",
    },
    NO: {
      code: "NO",
      name: "Norway",
    },
    PL: {
      code: "PL",
      name: "Poland",
    },
    PT: {
      code: "PT",
      name: "Portugal",
    },
    RO: {
      code: "RO",
      name: "Romania",
    },
    SM: {
      code: "SM",
      name: "San Marino",
    },
    RS: {
      code: "RS",
      name: "Serbia",
    },
    SK: {
      code: "SK",
      name: "Slovakia",
    },
    SI: {
      code: "SI",
      name: "Slovenia",
    },
    ES: {
      code: "ES",
      name: "Spain",
    },
    SE: {
      code: "SE",
      name: "Sweden",
    },
    CH: {
      code: "CH",
      name: "Switzerland",
    },
    GB: {
      code: "GB",
      name: "United Kingdom",
    },
  },
  {
    code: "CIS",
    title: "Commonwealth of Independent States",
    AM: {
      code: "AM",
      name: "Armenia",
    },
    AZ: {
      code: "AZ",
      name: "Azerbaijan",
    },
    BY: {
      code: "BY",
      name: "Belarus",
    },
    GE: {
      code: "GE",
      name: "Georgia",
    },
    KZ: {
      code: "KZ",
      name: "Kazakhstan",
    },
    KG: {
      code: "KG",
      name: "Kyrgyzstan",
    },
    MD: {
      code: "MD",
      name: "Moldova",
    },
    RU: {
      code: "RU",
      name: "Russia",
    },
    TJ: {
      code: "TJ",
      name: "Tajikistan",
    },
    TM: {
      code: "TM",
      name: "Turkmenistan",
    },
    UA: {
      code: "UA",
      name: "Ukraine",
    },
    UZ: {
      code: "UZ",
      name: "Uzbekistan",
    },
  },
  {
    code: "LATIN-AMERICA",
    title: "Latin America",
    AR: {
      code: "AR",
      name: "Argentina",
    },
    AW: {
      code: "AW",
      name: "Aruba",
    },
    BZ: {
      code: "BZ",
      name: "Belize",
    },
    BO: {
      code: "BO",
      name: "Bolivia",
    },
    BR: {
      code: "BR",
      name: "Brazil",
    },
    CL: {
      code: "CL",
      name: "Chile",
    },
    CO: {
      code: "CO",
      name: "Colombia",
    },
    CR: {
      code: "CR",
      name: "Costa Rica",
    },
    CU: {
      code: "CU",
      name: "Cuba",
    },
    DO: {
      code: "DO",
      name: "Dominican Republic",
    },
    EC: {
      code: "EC",
      name: "Ecuador",
    },
    SV: {
      code: "SV",
      name: "El Salvador",
    },
    FK: {
      code: "FK",
      name: "Falkland Islands",
    },
    GF: {
      code: "GF",
      name: "French Guiana",
    },
    GT: {
      code: "GT",
      name: "Guatemala",
    },
    GY: {
      code: "GY",
      name: "Guyana",
    },
    HT: {
      code: "HT",
      name: "Haiti",
    },
    HN: {
      code: "HN",
      name: "Honduras",
    },
    MX: {
      code: "MX",
      name: "Mexico",
    },
    AN: {
      code: "AN",
      name: "Curaçao",
    },
    NI: {
      code: "NI",
      name: "Nicaragua",
    },
    PA: {
      code: "PA",
      name: "Panama",
    },
    PY: {
      code: "PY",
      name: "Paraguay",
    },
    PE: {
      code: "PE",
      name: "Peru",
    },
    GS: {
      code: "GS",
      name: "South Georgia & South Sandwich Islands",
    },
    SR: {
      code: "SR",
      name: "Suriname",
    },
    TT: {
      code: "TT",
      name: "Trinidad & Tobago",
    },
    UY: {
      code: "UY",
      name: "Uruguay",
    },
    VE: {
      code: "VE",
      name: "Venezuela",
    },
  },
  {
    code: "SOUTHEAST-ASIA",
    title: "Southeast Asia",
    BN: {
      code: "BN",
      name: "Brunei",
    },
    MM: {
      code: "MM",
      name: "Myanmar (Burma)",
    },
    KH: {
      code: "KH",
      name: "Cambodia",
    },
    TL: {
      code: "TL",
      name: "Timor-Leste",
    },
    ID: {
      code: "ID",
      name: "Indonesia",
    },
    LA: {
      code: "LA",
      name: "Laos",
    },
    MY: {
      code: "MY",
      name: "Malaysia",
    },
    PH: {
      code: "PH",
      name: "Philippines",
    },
    SG: {
      code: "SG",
      name: "Singapore",
    },
    TH: {
      code: "TH",
      name: "Thailand",
    },
    VN: {
      code: "VN",
      name: "Vietnam",
    },
  },
  {
    code: "OTHER",
    title: "Other",
    GH: {
      code: "GH",
      name: "Ghana",
    },
    XK: {
      code: "XK",
      name: "Kosovo",
    },
    ET: {
      code: "ET",
      name: "Ethiopia",
    },
    AU: {
      code: "AU",
      name: "Australia",
    },
    AS: {
      code: "AS",
      name: "American Samoa",
    },
    AI: {
      code: "AI",
      name: "Anguilla",
    },
    AG: {
      code: "AG",
      name: "Antigua & Barbuda",
    },
    BS: {
      code: "BS",
      name: "Bahamas",
    },
    BB: {
      code: "BB",
      name: "Barbados",
    },
    BM: {
      code: "BM",
      name: "Bermuda",
    },
    BW: {
      code: "BW",
      name: "Botswana",
    },
    IO: {
      code: "IO",
      name: "British Indian Ocean Territory",
    },
    VU: {
      code: "VU",
      name: "Vanuatu",
    },
    VG: {
      code: "VG",
      name: "British Virgin Islands",
    },
    VI: {
      code: "VI",
      name: "U.S. Virgin Islands",
    },
    UM: {
      code: "UM",
      name: "U.S. Outlying Islands",
    },
    GM: {
      code: "GM",
      name: "Gambia",
    },
    GG: {
      code: "GG",
      name: "Guernsey",
    },
    HK: {
      code: "HK",
      name: "Hong Kong SAR China",
    },
    GD: {
      code: "GD",
      name: "Grenada",
    },
    GU: {
      code: "GU",
      name: "Guam",
    },
    JE: {
      code: "JE",
      name: "Jersey",
    },
    DG: {
      code: "DG",
      name: "Diego Garcia",
    },
    DM: {
      code: "DM",
      name: "Dominica",
    },
    ZM: {
      code: "ZM",
      name: "Zambia",
    },
    ZW: {
      code: "ZW",
      name: "Zimbabwe",
    },
    IN: {
      code: "IN",
      name: "India",
    },
    KY: {
      code: "KY",
      name: "Cayman Islands",
    },
    CM: {
      code: "CM",
      name: "Cameroon",
    },
    KE: {
      code: "KE",
      name: "Kenya",
    },
    KI: {
      code: "KI",
      name: "Kiribati",
    },
    CC: {
      code: "CC",
      name: "Cocos (Keeling) Islands",
    },
    LS: {
      code: "LS",
      name: "Lesotho",
    },
    LR: {
      code: "LR",
      name: "Liberia",
    },
    MU: {
      code: "MU",
      name: "Mauritius",
    },
    MG: {
      code: "MG",
      name: "Madagascar",
    },
    MO: {
      code: "MO",
      name: "Macao SAR China",
    },
    MW: {
      code: "MW",
      name: "Malawi",
    },
    MH: {
      code: "MH",
      name: "Marshall Islands",
    },
    MS: {
      code: "MS",
      name: "Montserrat",
    },
    NA: {
      code: "NA",
      name: "Namibia",
    },
    NR: {
      code: "NR",
      name: "Nauru",
    },
    NG: {
      code: "NG",
      name: "Nigeria",
    },
    NU: {
      code: "NU",
      name: "Niue",
    },
    NZ: {
      code: "NZ",
      name: "New Zealand",
    },
    IM: {
      code: "IM",
      name: "Isle of Man",
    },
    NF: {
      code: "NF",
      name: "Norfolk Island",
    },
    CX: {
      code: "CX",
      name: "Christmas Island",
    },
    SH: {
      code: "SH",
      name: "St. Helena",
    },
    CK: {
      code: "CK",
      name: "Cook Islands",
    },
    TC: {
      code: "TC",
      name: "Turks & Caicos Islands",
    },
    PK: {
      code: "PK",
      name: "Pakistan",
    },
    PW: {
      code: "PW",
      name: "Palau",
    },
    PG: {
      code: "PG",
      name: "Papua New Guinea",
    },
    PN: {
      code: "PN",
      name: "Pitcairn Islands",
    },
    PR: {
      code: "PR",
      name: "Puerto Rico",
    },
    RW: {
      code: "RW",
      name: "Rwanda",
    },
    WS: {
      code: "WS",
      name: "Samoa",
    },
    SZ: {
      code: "SZ",
      name: "Eswatini",
    },
    MP: {
      code: "MP",
      name: "Northern Mariana Islands",
    },
    SC: {
      code: "SC",
      name: "Seychelles",
    },
    VC: {
      code: "VC",
      name: "St. Vincent & Grenadines",
    },
    KN: {
      code: "KN",
      name: "St. Kitts & Nevis",
    },
    LC: {
      code: "LC",
      name: "St. Lucia",
    },
    SX: {
      code: "SX",
      name: "Sint Maarten",
    },
    SB: {
      code: "SB",
      name: "Solomon Islands",
    },
    SD: {
      code: "SD",
      name: "Sudan",
    },
    SL: {
      code: "SL",
      name: "Sierra Leone",
    },
    TZ: {
      code: "TZ",
      name: "Tanzania",
    },
    TK: {
      code: "TK",
      name: "Tokelau",
    },
    TO: {
      code: "TO",
      name: "Tonga",
    },
    TV: {
      code: "TV",
      name: "Tuvalu",
    },
    UG: {
      code: "UG",
      name: "Uganda",
    },
    FM: {
      code: "FM",
      name: "Micronesia",
    },
    FJ: {
      code: "FJ",
      name: "Fiji",
    },
    ER: {
      code: "ER",
      name: "Eritrea",
    },
    ZA: {
      code: "ZA",
      name: "South Africa",
    },
    SS: {
      code: "SS",
      name: "South Sudan",
    },
    JM: {
      code: "JM",
      name: "Jamaica",
    },
    DZ: {
      code: "DZ",
      name: "Algeria",
    },
    BH: {
      code: "BH",
      name: "Bahrain",
    },
    DJ: {
      code: "DJ",
      name: "Djibouti",
    },
    EG: {
      code: "EG",
      name: "Egypt",
    },
    EH: {
      code: "EH",
      name: "Western Sahara",
    },
    IL: {
      code: "IL",
      name: "Israel",
    },
    JO: {
      code: "JO",
      name: "Jordan",
    },
    IQ: {
      code: "IQ",
      name: "Iraq",
    },
    YE: {
      code: "YE",
      name: "Yemen",
    },
    QA: {
      code: "QA",
      name: "Qatar",
    },
    KM: {
      code: "KM",
      name: "Comoros",
    },
    KW: {
      code: "KW",
      name: "Kuwait",
    },
    LB: {
      code: "LB",
      name: "Lebanon",
    },
    LY: {
      code: "LY",
      name: "Libya",
    },
    MR: {
      code: "MR",
      name: "Mauritania",
    },
    MA: {
      code: "MA",
      name: "Morocco",
    },
    AE: {
      code: "AE",
      name: "United Arab Emirates",
    },
    OM: {
      code: "OM",
      name: "Oman",
    },
    PS: {
      code: "PS",
      name: "Palestinian Territories",
    },
    SA: {
      code: "SA",
      name: "Saudi Arabia",
    },
    SY: {
      code: "SY",
      name: "Syria",
    },
    SO: {
      code: "SO",
      name: "Somalia",
    },
    TN: {
      code: "TN",
      name: "Tunisia",
    },
    TD: {
      code: "TD",
      name: "Chad",
    },
    ML: {
      code: "ML",
      name: "Mali",
    },
    BD: {
      code: "BD",
      name: "Bangladesh",
    },
    BQ: {
      code: "BQ",
      name: "Caribbean Netherlands",
    },
    CW: {
      code: "CW",
      name: "Curaçao",
    },
    NL: {
      code: "NL",
      name: "Netherlands",
    },
    GL: {
      code: "GL",
      name: "Greenland",
    },
    BT: {
      code: "BT",
      name: "Bhutan",
    },
    IC: {
      code: "IC",
      name: "Canary Islands",
    },
    EA: {
      code: "EA",
      name: "Ceuta & Melilla",
    },
    GQ: {
      code: "GQ",
      name: "Equatorial Guinea",
    },
    BJ: {
      code: "BJ",
      name: "Benin",
    },
    CN: {
      code: "CN",
      name: "China",
    },
    TW: {
      code: "TW",
      name: "Taiwan",
    },
    KP: {
      code: "KP",
      name: "North Korea",
    },
    KR: {
      code: "KR",
      name: "South Korea",
    },
    AO: {
      code: "AO",
      name: "Angola",
    },
    CG: {
      code: "CG",
      name: "Congo - Brazzaville",
    },
    CD: {
      code: "CD",
      name: "Congo - Kinshasa",
    },
    CF: {
      code: "CF",
      name: "Central African Republic",
    },
    MN: {
      code: "MN",
      name: "Mongolia",
    },
    MV: {
      code: "MV",
      name: "Maldives",
    },
    NP: {
      code: "NP",
      name: "Nepal",
    },
    SJ: {
      code: "SJ",
      name: "Svalbard & Jan Mayen",
    },
    AF: {
      code: "AF",
      name: "Afghanistan",
    },
    IR: {
      code: "IR",
      name: "Iran",
    },
    GW: {
      code: "GW",
      name: "Guinea-Bissau",
    },
    CV: {
      code: "CV",
      name: "Cape Verde",
    },
    MZ: {
      code: "MZ",
      name: "Mozambique",
    },
    ST: {
      code: "ST",
      name: "São Tomé & Príncipe",
    },
    BI: {
      code: "BI",
      name: "Burundi",
    },
    FI: {
      code: "FI",
      name: "Finland",
    },
    LK: {
      code: "LK",
      name: "Sri Lanka",
    },
    TR: {
      code: "TR",
      name: "Turkey",
    },
    FO: {
      code: "FO",
      name: "Faroe Islands",
    },
    BF: {
      code: "BF",
      name: "Burkina Faso",
    },
    GA: {
      code: "GA",
      name: "Gabon",
    },
    GP: {
      code: "GP",
      name: "Guadeloupe",
    },
    GN: {
      code: "GN",
      name: "Guinea",
    },
    CI: {
      code: "CI",
      name: "Côte d’Ivoire",
    },
    YT: {
      code: "YT",
      name: "Mayotte",
    },
    MQ: {
      code: "MQ",
      name: "Martinique",
    },
    NE: {
      code: "NE",
      name: "Niger",
    },
    NC: {
      code: "NC",
      name: "New Caledonia",
    },
    RE: {
      code: "RE",
      name: "Réunion",
    },
    BL: {
      code: "BL",
      name: "St. Barthélemy",
    },
    MF: {
      code: "MF",
      name: "St. Martin",
    },
    PM: {
      code: "PM",
      name: "St. Pierre & Miquelon",
    },
    SN: {
      code: "SN",
      name: "Senegal",
    },
    TG: {
      code: "TG",
      name: "Togo",
    },
    WF: {
      code: "WF",
      name: "Wallis & Futuna",
    },
    PF: {
      code: "PF",
      name: "French Polynesia",
    },
    AX: {
      code: "AX",
      name: "Åland Islands",
    },
    JP: {
      code: "JP",
      name: "Japan",
    },
  },
];

export const dataServer = {
  ok: true,
  total: 17,
  filtered: 17,
  payments: [
    {
      id: 1,
      created: "2023-05-26T15:06:01+00:00",
      broadcaster: {
        id: null,
        nickname: null,
      },
      type: "balance-correction",
      amount: 999999,
      comment: "",
    },
    {
      id: 4,
      created: "2023-09-02T09:12:12+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 123,
      comment: "",
    },
    {
      id: 5,
      created: "2023-09-02T09:17:23+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 100,
      comment: "",
    },
    {
      id: 6,
      created: "2023-09-07T12:54:23+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 7,
      created: "2023-09-07T13:18:00+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 8,
      created: "2023-09-07T13:18:42+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 9,
      created: "2023-09-07T13:18:53+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 10,
      created: "2023-09-07T13:19:13+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 11,
      created: "2023-09-07T13:19:22+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10.5,
      comment: "",
    },
    {
      id: 12,
      created: "2023-09-07T13:19:30+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10.5,
      comment: "",
    },
    {
      id: 13,
      created: "2023-09-07T13:21:26+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10.53,
      comment: "",
    },
    {
      id: 14,
      created: "2023-09-07T13:22:22+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10.53,
      comment: "",
    },
    {
      id: 15,
      created: "2023-09-07T13:22:30+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10.54,
      comment: "",
    },
    {
      id: 16,
      created: "2023-09-07T13:22:36+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 17,
      created: "2023-09-07T13:23:06+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 18,
      created: "2023-11-01T16:09:26+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 10,
      comment: "",
    },
    {
      id: 19,
      created: "2023-11-01T16:09:32+00:00",
      broadcaster: {
        id: 4,
        nickname: "broadcaster",
      },
      type: "direct-transfer",
      amount: 30,
      comment: "",
    },
  ],
  broadcasters: [
    {
      id: 4,
      nickname: "broadcaster",
    },
  ],
};

export const defaultModel = [
  {
    id: 5,
    preview: undefined || "",
    imgSource: imgPlug1,
    categories: [],
    lang: "ru",
    name: "mode1",
    online: true,
  },
  {
    id: 51,
    preview: imgPlug || "",
    imgSource: imgPlug,
    categories: [],
    lang: "us",
    name: "teen2",
    online: true,
  },
  {
    id: 100,
    preview: "",
    imgSource: imgPlug,
    categories: [],
    lang: "us",
    name: "teen3",
    online: true,
  },
  {
    id: 101,
    preview: undefined || "",
    imgSource: undefined || "",
    categories: [],
    lang: "us",
    name: "teen4",
    gallery: [4, 5, 9],
    online: true,
  },
];

export const plugMessages = [
  {
    id: 0,
    type: "pin",
    title: "на машину",
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: true,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: false,
    text: "hello",
    deleted: 0,
  },
  {
    id: 1,
    created: "2023-12-16T20:40:49+00:00",
    from: { id: 180, type: "client", nickname: "lacef50789" },
    to: [],
    private: true,
    text: "hello",
    deleted: 0,
  },
  {
    id: 2,
    created: "2023-12-16T22:03:38+00:00",
    from: {
      id: 180,
      type: "client",
      nickname: "lacef50789",
    },
    to: [],
    private: false,
    text: "привет",
    deleted: 0,
  },
  {
    id: 3,
    created: "2023-12-16T22:03:50+00:00",
    from: {
      id: 191,
      type: "client",
      nickname: "lelis68867",
    },
    to: [],
    private: true,
    text: "всем привет",
    deleted: 0,
  },
  {
    id: 3,
    created: "2023-12-16T22:03:50+00:00",
    from: {
      id: 191,
      type: "client",
      nickname: "lelis68867",
    },
    to: [],
    private: false,
    text: "всем привет",
    deleted: 0,
  },
  {
    id: 4,
    created: "2023-12-17T11:03:20+00:00",
    from: {
      id: 4,
      type: "broadcaster",
      nickname: "broadcaster",
    },
    to: [],
    private: false,
    text: "всем привет, это мой стрим",
    deleted: 0,
  },
  {
    id: 5,
    created: "2023-12-17T22:03:50+00:00",
    from: {
      id: 191,
      type: "client",
      nickname: "lelis68867",
    },
    to: [],
    private: true,
    text: "привет",
    deleted: 0,
  },
  {
    id: 5.5,
    created: "2023-12-17T22:03:50+00:00",
    deleted: 0,
    mode: "free-chat",
    type: TYPE_ENUM.switched,
  },
  {
    id: 6,
    created: "2023-12-18T22:03:50+00:00",
    from: {
      id: 191,
      type: "client",
      nickname: "lelis68867",
    },
    to: [],
    private: false,
    text: "как дела",
    deleted: 0,
  },
];

export const testProviders = [
  {
    id: 18,
    enabled: true,
    img_tag: "",
    gateway: "TwoCheckoutPlus",
    name: "CCBill",
    description: "Платеж в долларах США",
    packages: [],
    parameters: [],
  },
  {
    id: 17,
    enabled: true,
    img_tag:
      '<img src="https://controlcenter.billing.creditcard/css/skin/cardbilling/images/pageHeader/cardbilling-logo.png">',
    gateway: "TwoCheckoutPlus",
    name: "CardBilling (Verotel)",
    description: "Платежи картой в Евро",
    packages: [
      {
        id: 16,
        enabled: true,
        currency: "EUR",
        cost: 9.99,
        credits: 150,
        description: "150 токенов за €9.99",
        parameters: [],
      },
      {
        id: 17,
        enabled: true,
        currency: "EUR",
        cost: 15.99,
        credits: 240,
        description: "240 токенов за €15.99",
        parameters: [],
      },
      {
        id: 18,
        enabled: true,
        currency: "EUR",
        cost: 49.99,
        credits: 840,
        description: "840 токенов за €49.99",
        parameters: [],
      },
      {
        id: 19,
        enabled: true,
        currency: "EUR",
        cost: 99.99,
        credits: 1680,
        description: "1680 токенов за €99.99",
        parameters: "",
      },
    ],
    parameters: [],
  },
  {
    id: 15,
    enabled: true,
    img_tag: '<img src="img/2Checkout-logo.svg">',
    gateway: "TwoCheckoutPlus",
    name: "2Checkout / Verifone",
    description: "Пополнение счета через 2Checkout",
    packages: [
      {
        id: 6,
        enabled: true,
        currency: "USD",
        cost: 9.99,
        credits: 100,
        description: "100 токенов за $9.99",
        parameters: [],
      },
      {
        id: 13,
        enabled: true,
        currency: "USD",
        cost: 15.99,
        credits: 220,
        description: "220 токенов за $15.99",
        parameters: [],
      },
      {
        id: 14,
        enabled: true,
        currency: "USD",
        cost: 49.99,
        credits: 800,
        description: "800 токенов за $49.99",
        parameters: [],
      },
      {
        id: 15,
        enabled: true,
        currency: "USD",
        cost: 99.99,
        credits: 1600,
        description: "1600 токенов за $99.99",
        parameters: [],
      },
    ],
    parameters: [
      {
        name: "accountNumber",
        value: "252642090279",
      },
      {
        name: "demoMode",
        value: "true",
      },
      {
        name: "secretWord",
        value: "|M[L73w%)^20dNkBlSzg",
      },
      {
        name: "secretWordINS",
        value: "V8!%cv%3$sg4-@8$#YXBex2H5$GEvcX$!6k@d*HSVgYFxQnFRgg5c4jE!2Qg-%Ht",
      },
    ],
  },
];

export const testLanguage = {
  ca: { title: "Català", flag: "es-ct", code: "ca", country: "ES" },
  de: { title: "Deutsch", flag: "de", code: "de", country: "DE" },
  en: { title: "English", flag: "us", code: "en", country: "US" },
  es: { title: "Español", flag: "es", code: "es", country: "ES" },
  it: { title: "Italiano", flag: "it", code: "it", country: "IT" },
  ru: { title: "Русский", flag: "ru", code: "ru", country: "RU" },
};

export const testSiteInfo = [
  {
    title: "Пол",
    id: 1,
    url: "gender",
    subcategories: [
      {
        title: "Все модели",
        id: 15,
        url: "all",
      },
      {
        title: "Девушки",
        id: 11,
        url: "girls",
        multiselect: true,
      },
      {
        title: "Парни",
        id: 12,
        url: "guys",
        multiselect: true,
      },
      {
        title: "Трансы",
        id: 16,
        url: "trans",
        multiselect: true,
      },
    ],
  },
  {
    id: 4,
    url: "show",
    subcategories: [
      {
        title: "Вне дома",
        keywords: "Вне дома",
        id: 8,
        url: "outdoors",
        multiselect: true,
      },
      {
        title: "Вибратор",
        keywords: "Вибратор",
        id: 17,
        url: "vibrator",
        multiselect: true,
      },
      {
        title: "Дилдо",
        keywords: "Дилдо",
        id: 18,
        url: "dildo",
        multiselect: true,
      },
      {
        title: "Пары",
        keywords: "Пары",
        id: 19,
        url: "couples",
        multiselect: true,
      },
      {
        title: "Подгядки",
        keywords: "Подгядки",
        id: 20,
        url: "spy-private",
        multiselect: true,
      },
    ],
  },
  {
    title: "Фильтры",
    id: 5,
    url: "filters",
    multiselect: true,
    subcategories: [
      {
        title: "Популярные",
        keywords: "Популярные",
        id: 9,
        url: "popular",
        subcategories: [
          {
            title: "Групповой секс",
            keywords: "Групповой секс",
            id: 24,
            url: "group-sex",
            multiselect: true,
          },
          {
            title: "Большая грудь",
            keywords: "Большая грудь",
            id: 25,
            url: "big-tits",
            multiselect: true,
          },
          {
            title: "Анал",
            keywords: "Анал",
            id: 32,
            url: "anal",
            multiselect: true,
          },
          {
            title: "Большая задница",
            keywords: "Большая задница",
            id: 33,
            url: "big-ass",
            multiselect: true,
          },
          {
            title: "Лесбиянки",
            keywords: "Лесбиянки",
            id: 34,
            url: "lesbians",
            multiselect: true,
          },
          {
            title: "Беременные",
            keywords: "Беременные",
            id: 35,
            url: "pregnant",
            multiselect: true,
          },
          {
            title: "Сквирт",
            keywords: "Сквирт",
            id: 36,
            url: "squirt",
            multiselect: true,
          },
          {
            title: "Жесткий секс",
            keywords: "Жесткий секс",
            id: 37,
            url: "hardcore",
            multiselect: true,
          },
          {
            title: "Минет",
            keywords: "Минет",
            id: 38,
            url: "blowjob",
            multiselect: true,
          },
          {
            title: "БДСМ",
            keywords: "БДСМ",
            id: 39,
            url: "bdsm",
            multiselect: true,
          },
        ],
      },
      {
        title: "Возраст",
        id: 78,
        url: "age",
        subcategories: [
          {
            title: "18-22",
            keywords: "18-22",
            id: 79,
            url: "18-22",
          },
          {
            title: "23-30",
            keywords: "23-30",
            id: 80,
            url: "23-30",
          },
          {
            title: "31-45",
            keywords: "31-45",
            id: 81,
            url: "31-45",
          },
          {
            title: "45+",
            keywords: "45+",
            id: 82,
            url: "46-99",
          },
        ],
      },
      {
        title: "Регион",
        keywords: "Region",
        id: 10,
        url: "region",
        subcategories: [
          {
            title: "Северная Америка",
            keywords: "Северная Америка",
            id: 40,
            url: "usa-uk",
          },
          {
            title: "Европа",
            keywords: "Европа",
            id: 41,
            url: "eu",
          },
          {
            title: "Россия / Украина",
            keywords: "Россия / Украина",
            id: 42,
            url: "cis",
          },
          {
            title: "Латинская Америка",
            keywords: "Латинская Америка",
            id: 43,
            url: "latin",
          },
          {
            title: "Азия",
            keywords: "Азия",
            id: 44,
            url: "asia",
          },
          {
            title: "Другие регионы",
            keywords: "Другие регионы",
            id: 45,
            url: "other",
          },
        ],
      },
      {
        title: "Язык",
        keywords: "Язык",
        id: 22,
        url: "language",
        multiselect: true,
        subcategories: [
          {
            title: "Английский",
            keywords: "Английский язык",
            id: 46,
            url: "en",
            multiselect: true,
          },
          {
            title: "Французский",
            keywords: "Французский язык",
            id: 88,
            url: "fr",
            multiselect: true,
          },
          {
            title: "Испанский",
            keywords: "Испанский язык",
            id: 89,
            url: "es",
            multiselect: true,
          },
          {
            title: "Русский",
            keywords: "Русский язык",
            id: 90,
            url: "ru",
            multiselect: true,
          },
          {
            title: "Немецкий",
            keywords: "Немецкий язык",
            id: 91,
            url: "de",
            multiselect: true,
          },
          {
            title: "Итальянский",
            keywords: "Итальянский язык",
            id: 92,
            url: "it",
            multiselect: true,
          },
          {
            title: "Шведский",
            keywords: "Шведский язык",
            id: 93,
            url: "sw",
            multiselect: true,
          },
          {
            title: "Португальский",
            keywords: "Португальский язык",
            id: 94,
            url: "pt",
            multiselect: true,
          },
          {
            title: "Датский",
            keywords: "Датский язык",
            id: 95,
            url: "da",
            multiselect: true,
          },
          {
            title: "Другие",
            keywords: "Другие",
            id: 96,
            url: "other",
            multiselect: true,
          },
        ],
      },
      {
        title: "Внешность",
        keywords: "Appearance",
        id: 23,
        url: "appearance",
        multiselect: true,
        subcategories: [
          {
            title: "Тело",
            keywords: "Тело",
            id: 47,
            url: "body",
            subcategories: [
              {
                title: "Стройное",
                keywords: "Стройное",
                id: 48,
                url: "thin",
                multiselect: true,
              },
              {
                title: "Обычное",
                keywords: "Обычное",
                id: 49,
                url: "regular",
                multiselect: true,
              },
              {
                title: "Атлетическое",
                keywords: "Атлетическое",
                id: 50,
                url: "athletic",
                multiselect: true,
              },
              {
                title: "Несколько лишних килограмм",
                keywords: "Несколько лишних килограмм",
                id: 51,
                url: "extra",
                multiselect: true,
              },
              {
                title: "Большой и высокий",
                keywords: "Большой и высокий",
                id: 52,
                url: "bbw",
                multiselect: true,
              },
            ],
          },
          {
            title: "Грудь",
            keywords: "Грудь",
            id: 53,
            url: "breasts",
            subcategories: [
              {
                title: "Маленькая",
                keywords: "Маленькая",
                id: 54,
                url: "small",
                multiselect: true,
              },
              {
                title: "Средний размер груди",
                keywords: "Средняя грудь",
                id: 55,
                url: "medium",
                multiselect: true,
              },
              {
                title: "Большая грудь",
                keywords: "Большая грудь",
                id: 56,
                url: "large",
                multiselect: true,
              },
              {
                title: "Огромная грудь",
                keywords: "Огромная грудь",
                id: 57,
                url: "huge",
                multiselect: true,
              },
            ],
          },
          {
            title: "Размер задницы",
            keywords: "Размер задницы",
            id: 58,
            url: "butt",
            subcategories: [
              {
                title: "Маленькая задница",
                keywords: "Маленькая задница",
                id: 59,
                url: "small",
                multiselect: true,
              },
              {
                title: "Средняя",
                keywords: "Средняя",
                id: 60,
                url: "medium",
                multiselect: true,
              },
              {
                title: "Большая задница",
                keywords: "Большая задница",
                id: 61,
                url: "big",
                multiselect: true,
              },
            ],
          },
          {
            title: "Этничность",
            keywords: "Этничность",
            id: 62,
            url: "ethnicity",
            subcategories: [
              {
                title: "Азиатская",
                keywords: "Азиатская",
                id: 63,
                url: "asia",
                multiselect: true,
              },
              {
                title: "Белая",
                keywords: "Белая",
                id: 64,
                url: "caucasian",
                multiselect: true,
              },
              {
                title: "Темненькая",
                keywords: "Темненькая",
                id: 65,
                url: "ebony",
                multiselect: true,
              },
              {
                title: "Латиноамериканская",
                keywords: "Латиноамериканская",
                id: 66,
                url: "latino",
                multiselect: true,
              },
              {
                title: "Индийская",
                keywords: "Индийская",
                id: 67,
                url: "indian",
                multiselect: true,
              },
              {
                title: "Арабская",
                keywords: "Арабская",
                id: 68,
                url: "arab",
                multiselect: true,
              },
            ],
          },
          {
            title: "Цвет волос",
            keywords: "Цвет волос",
            id: 69,
            url: "hair",
            subcategories: [
              {
                title: "Блондинка",
                keywords: "Блондинка",
                id: 70,
                url: "blonde",
                multiselect: true,
              },
              {
                title: "Брюнетка",
                keywords: "Брюнетка",
                id: 71,
                url: "brunette",
                multiselect: true,
              },
              {
                title: "Рыжая",
                keywords: "Рыжая",
                id: 72,
                url: "redhead",
                multiselect: true,
              },
              {
                title: "Лысая",
                keywords: "Лысая",
                id: 73,
                url: "bald",
                multiselect: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Сортировка",
    keywords: "Сортировка",
    id: 21,
    url: "sort-by",
    subcategories: [
      {
        title: "Самые популярные комнаты",
        keywords: "Самые популярные комнаты",
        id: 26,
        url: "popular-chat-rooms",
      },
      {
        title: "Наивысший балл",
        keywords: "Наивысший балл",
        id: 74,
        url: "highest",
      },
      {
        title: "Новые модели",
        keywords: "Новые модели",
        id: 75,
        url: "new-models",
      },
      {
        title: "Новые чат-комнаты",
        keywords: "Новые чат-комнаты",
        id: 76,
        url: "new-rooms",
      },
      {
        title: "Мои избранные",
        keywords: "Мои избранные",
        id: 77,
        url: "favorites",
      },
    ],
  },
];

export const testLovenseTips = {
  levels: {
    level1: {
      min: "1",
      max: "10",
      time: "3",
      rLevel: 0,
      vLevel: 5,
    },
    level2: {
      min: "11",
      max: "50",
      time: "6",
      rLevel: 0,
      vLevel: 10,
    },
    level3: {
      min: "51",
      max: "100",
      time: "10",
      rLevel: 0,
      vLevel: 10,
    },
    level4: {
      min: "101",
      max: "200",
      time: "20",
      rLevel: 0,
      vLevel: 15,
    },
    level5: {
      min: "201",
      max: "infinity",
      time: "30",
      rLevel: 0,
      vLevel: 20,
    },
  },
  special: {
    pause: {
      enable: true,
      time: "5",
      token: "20",
    },
    clear: {
      enable: true,
      time: 0,
      token: "21",
    },
    random: {
      enable: true,
      time: 0,
      token: "22",
    },
    wave: {
      enable: true,
      time: "3",
      token: "23",
    },
    pulse: {
      enable: true,
      time: "4",
      token: "24",
    },
    earthquake: {
      enable: true,
      time: "5",
      token: "25",
    },
    fireworks: {
      enable: true,
      time: "6",
      token: "26",
    },
  },
};

export const testLovenseToys = [
  {
    id: "c08ada3a264c",
    name: "",
    status: "on",
    type: "ambi",
  },
];

export const testLovenseQueue = {
  running: {
    amount: 999,
    tipperName: "BlueAvatar",
    time: 40,
    module: "Basic Level",
    cParameter: [],
    level: "",
    reactToys: [
      {
        toyId: "c08ada3a264c",
        status: 1,
        toyType: "ambi",
        vibrate: 19,
        rotate: 0,
        airpump: 0,
      },
    ],
  },
  queue: [
    {
      amount: 20,
      tipperName: "john",
    },
  ],
  waiting: [
    {
      amount: 20,
      tipperName: "john",
    },
  ],
};

export const testVideos = [
  {
    id: 388,
    type: "video",
    title: "qwrrt",
    access: "guest",
  },
  {
    id: 387,
    type: "video",
    title: "",
    access: "guest",
  },
  {
    id: 386,
    type: "video",
    title: "",
    access: "guest",
  },
  {
    id: 385,
    type: "video",
    title: "",
    access: "guest",
  },
];

export const testSnapshot = [
  {
    id: 384,
    type: "snapshot",
    title: "test",
    access: "guest",
  },
  {
    id: 383,
    type: "snapshot",
    title: "t",
    access: "guest",
  },
  {
    id: 382,
    type: "snapshot",
    title: "teeeest",
    access: "guest",
  },
];

import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import Spinner from "../mui/Spinner";
import { useTranslation } from "react-i18next";

import useGetBroadcasterList from "../../hooks/useGetBroadcasterList";
import useGridDisplay from "../../hooks/useGridDisplay";
import ShowContainer from "../mui/ShowContainer";

export const DELAY_GET_BROADCAST = 15 * 1000;
// const COUNT_PAGE_HOME = 60;
const COUNT_PAGE_HOME = 28;

import ModelCard from "./model_card/ModelCard";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setModels } from "../../store/homeSlice";
// const ModelCard = lazy(() => import("./model_card/ModelCard"));
const Pagination = lazy(() => import("../mui/Pagination"));

///HomeContent - основной контент домашний страницы, модели и пагинация
export default function HomeContent({ collapsed }) {
  const dispatch = useDispatch();
  const models = useSelector((state) => state.home.models);
  const currentPage = useSelector((state) => state.home.currentPage);
  const total = useSelector((state) => state.home.total);

  const scrollPositionRef = useRef(0);

  const [modelsClass, setModelsClass] = useState("");
  const [loadingModels, setLoadingModels] = useState(true);

  const [isUpdate, setIsUpdate] = useState(1);
  const { t } = useTranslation();

  const handleModelUpdate = (newModels) => {
    scrollPositionRef.current = window.scrollY;
    dispatch(setModels(newModels));
    setLoadingModels(false);
  };

  const changeCurrentPage = (val) => {
    setLoadingModels(true);
    dispatch(setCurrentPage(val));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // CREATE GRID MODELS
  useGridDisplay({ collapsed, setModelsClass, models });

  // BROADCASTER LIST
  useGetBroadcasterList({
    COUNT_PAGE_HOME,
    DELAY_GET_BROADCAST,
    setLoadingModels,
    handleModelUpdate,
    setIsUpdate,
  });

  useEffect(() => {
    if (!loadingModels) window.scrollTo(0, scrollPositionRef.current);
  }, [loadingModels]);

  const displayModels = !!modelsClass && !!models?.length;

  return (
    <>
      {displayModels && !loadingModels ? (
        <div className="home__models__containers">
          <div className={`home__models ${modelsClass}`}>
            {models?.map((el, indx) => (
              <ModelCard card={el} key={indx} isUpdate={isUpdate} />
            ))}
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          {loadingModels ? <Spinner /> : <h3> {t("No models")}</h3>}
        </div>
      )}

      <ShowContainer condition={total / COUNT_PAGE_HOME > 1}>
        <Suspense>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={changeCurrentPage}
            MAX_PAGE={Math.ceil(total / COUNT_PAGE_HOME)}
          />
        </Suspense>
      </ShowContainer>
    </>
  );
}
